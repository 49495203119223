<template>
  <c-accordion-item
    :default-is-open="false"
    border-color="neutral.lightGray"
    border-top-width="0px"
    border-bottom-width="1px"
    margin-bottom="8px"
  >
    <c-accordion-header
      padding-inline="8px"
      padding-block="10px"
    >
      <c-flex
        align-items="center"
        justify-content="space-between"
        width="100%"
      >
        <c-text
          font-size="16px"
          font-weight="500"
          text-align="left"
        >
          {{ mealType }}
        </c-text>
        <c-text
          flex-shrink="0"
          font-size="16px"
        >
          {{ mealPortion }} {{ mealPortionUnit }}
        </c-text>
      </c-flex>
      <c-accordion-icon
        size="24px"
        name="chevron-down"
      />
    </c-accordion-header>
    <c-accordion-panel 
      padding="8px"
    >
      <c-text
        color="neutral.888888"
        font-size="16px"
        margin-right="auto"
      >
        Pilihan golongan makanan
      </c-text>
      <c-grid
        margin-top="8px"
        template-columns="repeat(auto-fill, minmax(70px, 1fr))"
        gap="0"
      >
        <MealPortion
          v-for="(meal, mealIdx) in mealItems"
          :key="mealIdx"
          :image="meal?.photoUrl || require('@/assets/images/image-gray.png')"
          :name="meal?.foodName || '-'"
          :household-measurement="calcHouseholdMeasurement(meal)"
          :household-measurement-unit="meal?.householdMeasurementUnitPerPortion || meal?.householdMeasurementUnit || ''"
          :serving-size="calcServingSize(meal)"
          :serving-size-unit="meal?.servingSizeUnitPerPortion || ''"
        />
      </c-grid>
    </c-accordion-panel>
  </c-accordion-item>
  <!-- <c-accordion :allow-toggle="true">
  </c-accordion> -->
</template>

<script>
import MealPortion from '@/components/meal-portion.vue'
import { CFlex } from '@chakra-ui/vue'
import { convertToFraction } from '@/utils/fraction'

export default {
  name: 'CardMealPortionGuide',
  components: { CFlex, MealPortion },
  props: {
    mealType: {
      type: [String, Number],
      required: true,
    },
    mealPortion: {
      type: [String, Number],
      required: true,
    },
    mealPortionDecimal: {
      type: [String, Number],
      required: true,
    },
    mealPortionUnit: {
      type: String,
      required: true,
    },
    mealItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    convertToFraction,
    calcHouseholdMeasurement(meal) {
      if (this.mealPortion && (meal?.householdMeasurementPerPortion || meal?.householdMeasurement)) {
        let size = meal?.householdMeasurementPerPortion ?? meal?.householdMeasurement
  
        const parseSize = size ? parseFloat(size) : 0
        if (this.mealPortionDecimal) {
          const result = parseFloat(this.mealPortionDecimal) * parseSize
    
          if (Number.isInteger(result)) {
            return result
          }
    
          return this.convertToFraction(result)
        }
        return parseSize
      }
      return ''
    },
    calcServingSize(meal) {
      if (this.mealPortion && meal?.servingSizePerPortion) {
        let size = meal?.servingSizePerPortion

        const parseSize = size ? parseFloat(size) : 0
        if (this.mealPortionDecimal) {
          const result = parseFloat(this.mealPortionDecimal) * parseSize

          if (Number.isInteger(result)) {
            return result
          }

          return this.convertToFraction(result)
        }
        return parseSize
      }
      return ''
    },
  },
}
</script>
