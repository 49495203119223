var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('Tabs', {
    attrs: {
      "active-tab": "recommendation",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm.tabs
    }
  }, [_vm.isProgramConsultationBasic ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info', {
    attrs: {
      "variant": "warning",
      "text": "Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic",
      "padding": "16px 28px",
      "font-size": "14px"
    }
  })], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: _vm.isRoleNutritionist ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'admin.clients.meal-plan.meal-plan-schedule'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "28px",
      "font-weight": "700"
    }
  }, [_vm._v(" Rekomendasi Menu ")])], 1), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": _vm.preview ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": _vm.isLoadingData || !_vm.isEdited || _vm.isProgramConsultationBasic
    },
    on: {
      "click": function click($event) {
        var _vm$$route$query, _vm$$route$query2;

        _vm.isEdited && !_vm.isProgramConsultationBasic ? _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.menu-recommendation.edit',
          query: {
            day: (_vm$$route$query = _vm.$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.day ? Number((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.day) % 10 : 1
          }
        })) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getHasPreviewData ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "container-portion-guide",
      "flex-direction": "column",
      "flex-shrink": "0",
      "width": "330px",
      "height": "100%",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "height": "40px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "600"
    }
  }, [_vm._v(" Panduan Porsi ")])], 1), _vm.mealPlanSchedules.length > 0 ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.mealPlanSchedules, function (mealPlanSchedule) {
    var _mealPlanSchedule$mea;

    return _c('c-box', {
      key: mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime,
      staticClass: "pulse",
      attrs: {
        "id": "portion-guide-".concat(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime)
      }
    }, [_c('MealTimeHeader', {
      attrs: {
        "meal-time": _vm.constants.mealPlanValueToLabel[mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime],
        "calories-amount": mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.caloriesAmount,
        "start-time": _vm.format24Time(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.startTime, 'HH:mm'),
        "end-time": _vm.format24Time(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.endTime, 'HH:mm')
      }
    }), (mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : (_mealPlanSchedule$mea = mealPlanSchedule.mealComposition) === null || _mealPlanSchedule$mea === void 0 ? void 0 : _mealPlanSchedule$mea.length) > 0 ? _c('c-accordion', {
      attrs: {
        "allow-toggle": true
      }
    }, _vm._l(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealComposition, function (item, itemIdx) {
      return _c('CardMealPortionGuide', {
        key: "".concat(mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime, "-").concat(itemIdx),
        attrs: {
          "meal-type": item === null || item === void 0 ? void 0 : item.foodGroup,
          "meal-portion": "".concat((item === null || item === void 0 ? void 0 : item.portion) || '-'),
          "meal-portion-decimal": "".concat((item === null || item === void 0 ? void 0 : item.portionDecimal) || '0'),
          "meal-portion-unit": "Porsi",
          "meal-items": _vm.mergeMealItems(item === null || item === void 0 ? void 0 : item.portionDecimal, item === null || item === void 0 ? void 0 : item.mealItems, item === null || item === void 0 ? void 0 : item.additionalMealItems) || []
        }
      });
    }), 1) : _c('c-flex', {
      attrs: {
        "justify-content": "center",
        "align-items": "center",
        "width": "100%",
        "height": "100%"
      }
    }, [_c('NoData2', {
      attrs: {
        "text": "Tidak ada data ".concat(_vm.constants.mealPlanValueToLabel[mealPlanSchedule === null || mealPlanSchedule === void 0 ? void 0 : mealPlanSchedule.mealTime])
      }
    })], 1)], 1);
  }), 1) : _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('NoData2', {
    attrs: {
      "text": "Tidak ada data"
    }
  })], 1)], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "neutral.888888",
      "margin-right": "8px"
    }
  }), _c('PreviewMenuRecommendation', {
    attrs: {
      "calendars": _vm.calendars,
      "data": _vm.preview,
      "is-loading": _vm.isLoadingData
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }