<template>
  <c-flex
    flex-direction="column"
    justify-content="space-between"
    align-items="center"
    background-color="#C7F9E333"
    padding-block="16px"
    padding-inline="12px"
    border-radius="12px"
    gap="4px"
    border-bottom-width="1px"
    border-color="neutral.lightGray"
  >
    <c-text
      font-size="18px"
      font-weight="600"
      color="primary.400"
    >
      {{ mealTime }} - {{ caloriesAmount }}kkal
    </c-text>
    <c-flex
      justify-content="center"
      align-items="center"
      flex-shrink="0"
    >
      <c-text
        font-size="16px"
        color="neutral.gray"
      >
        {{ startTime }} - {{ endTime }}
      </c-text>
    </c-flex>
  </c-flex>
</template>

<script>
import { CFlex, CText } from '@chakra-ui/vue'

export default {
  name: 'MealTimeHeader',
  components: { CFlex, CText },
  props: {
    mealTime: {
      type: [String, Number],
      default: '-', // Sarapan
    },
    caloriesAmount: {
      type: [String, Number],
      default: '-', // 200
    },
    startTime: {
      type: [String, Number],
      default: '-', // 06:00
    },
    endTime: {
      type: [String, Number],
      default: '-', // 07:00
    },
  },
}
</script>
