<template>
  <c-box>
    <Tabs
      active-tab="recommendation"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="tabs"
    >
      <c-box
        v-if="isProgramConsultationBasic"
        margin-bottom="16px"
      >
        <Info 
          variant="warning"
          text="Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic"
          padding="16px 28px"
          font-size="14px"
        />
      </c-box>
      <c-flex
        align-items="center"
        justify-content="space-between"
        margin-bottom="12px"
      >
        <c-flex
          align-items="center"
          gap="16px"
        >
          <c-button
            variant="ghost"
            width="30px"
            min-width="30px"
            height="30px"
            margin="0"
            padding="0"
            @click="$router.push({
              ...$route,
              name: isRoleNutritionist ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'admin.clients.meal-plan.meal-plan-schedule',
            })
            "
          >
            <c-box
              :width="['12px', '15px']"
              :height="['12px', '15px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
                style="transform: rotate(90deg);"
              />
            </c-box>
          </c-button>
          <c-text
            color="neutral.superDarkGray"
            font-size="28px"
            font-weight="700"
          >
            Rekomendasi Menu
          </c-text>
        </c-flex>

        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="preview ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="isLoadingData || !isEdited || isProgramConsultationBasic"
          @click="isEdited && !isProgramConsultationBasic ? $router.push({
            ...$route,
            name: 'nutri.meal-plan.menu-recommendation.edit',
            query: {
              day: $route.query?.day ? Number($route.query?.day) % 10 : 1,
            },
          }) : ''"
        >
          {{
            getHasPreviewData
              ? 'Edit'
              : 'Tambah'
          }}
        </BaseButton>
      </c-flex>
      <c-flex
        width="100%"
      >
        <!-- Detail Panduan Porsi -->
        <c-flex
          id="container-portion-guide"
          flex-direction="column"
          flex-shrink="0"
          width="330px"
          height="100%"
          gap="16px"
        >
          <c-flex
            align-items="center"
            height="40px"
          >
            <c-text
              font-size="18px"
              font-weight="600"
            >
              Panduan Porsi
            </c-text>
          </c-flex>
          <c-flex
            v-if="mealPlanSchedules.length > 0"
            flex-direction="column"
            gap="16px"
          >
            <c-box
              v-for="(mealPlanSchedule) in mealPlanSchedules"
              :key="mealPlanSchedule?.mealTime"
              :id="`portion-guide-${mealPlanSchedule?.mealTime}`"
              class="pulse"
            >
              <MealTimeHeader
                :meal-time="constants.mealPlanValueToLabel[mealPlanSchedule?.mealTime]"
                :calories-amount="mealPlanSchedule?.caloriesAmount"
                :start-time="format24Time(mealPlanSchedule?.startTime, 'HH:mm')"
                :end-time="format24Time(mealPlanSchedule?.endTime, 'HH:mm')"
              />
              <c-accordion
                v-if="mealPlanSchedule?.mealComposition?.length > 0"
                :allow-toggle="true"
              >
                <CardMealPortionGuide
                  v-for="(item, itemIdx) in mealPlanSchedule?.mealComposition"
                  :key="`${mealPlanSchedule?.mealTime}-${itemIdx}`"
                  :meal-type="item?.foodGroup"
                  :meal-portion="`${item?.portion || '-'}`"
                  :meal-portion-decimal="`${item?.portionDecimal || '0'}`"
                  :meal-portion-unit="`Porsi`"
                  :meal-items="
                    mergeMealItems(
                      item?.portionDecimal,
                      item?.mealItems,
                      item?.additionalMealItems
                    ) || []
                  "
                />
              </c-accordion>
              <c-flex
                v-else
                justify-content="center"
                align-items="center"
                width="100%"
                height="100%"
              >
                <NoData2
                  :text="`Tidak ada data ${constants.mealPlanValueToLabel[mealPlanSchedule?.mealTime]}`"
                />
              </c-flex>
            </c-box>
          </c-flex>
          <c-flex
            v-else
            justify-content="center"
            align-items="center"
            width="100%"
            height="100%"
          >
            <NoData2
              text="Tidak ada data"
            />
          </c-flex>
        </c-flex>

        <c-divider
          orientation="vertical"
          border-color="neutral.888888"
          margin-right="8px"
        />

        <!-- Preview Menu Rekomendasi -->
        <PreviewMenuRecommendation
          :calendars="calendars"
          :data="preview"
          :is-loading="isLoadingData"
        />
      </c-flex>
    </Tabs>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import PreviewMenuRecommendation from '@/components/meal-plan/_widgets/preview-menu-recommendation.vue'
import Info from '@/components/info.vue'
import MealTimeHeader from '@/components/meal-time-header.vue'
import CardMealPortionGuide from '@/components/card-meal-portion-guide.vue'
import NoData2 from '@/components/no-data-2.vue'
import {
  mealPlanValueToLabel,
} from '@/constants/meal-plans'
import { format24Time } from '@/utils/format-24-time'

export default {
  name: 'MealPlanMenuRecommendationContainer',
  components: { 
    Tabs,
    BaseButton,
    PreviewMenuRecommendation,
    Info,
    MealTimeHeader,
    CardMealPortionGuide,
    NoData2,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: [Object, null],
      default: null,
    },
    calendars: {
      type: Array,
      default: () => [],
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    mealPlanSchedules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    getHasPreviewData() {
      return !_.isEmpty(this.preview)
    },
    constants() {
      return {
        mealPlanValueToLabel,
      }
    },
  },
  methods: {
    isAllFormValid,
    format24Time,
    mergeMealItems(portionDecimal, mealItems, additionalMealItems) {
      const remapMealItems =
        mealItems?.map((v) => {
          return {
            ...v,
            servingSize: parseFloat(v?.householdMeasurement || 0),
            servingSizeUnit: v?.householdMeasurementUnit,
          }
        }) || []
      const remapAdditionalMealItems =
        additionalMealItems?.map((v) => {
          return {
            ...v,
            servingSize:
              parseFloat(v?.householdMeasurementPerPortion || 0) *
              parseFloat(portionDecimal || 0),
            servingSizeUnit: v?.householdMeasurementUnitPerPortion,
          }
        }) || []

      return remapMealItems.concat(remapAdditionalMealItems)
    },
  },
}
</script>
